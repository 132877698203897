export const DEFAULT_CITY_LONG_LAT = { longitude: -73.9862, latitude: 40.748 }; // NEW YORK
export const MAX_PUBLISHED_DAYS_AGO = 7; //days
export const X_API_KEY = "M6JGAMnKer9gvvkFVEDn16opHCmF6SL02GdhAgHk";
export const WEATHER_API_HOST = "https://api.weatherbug.io";
export const DEGREE_SYMBOL = "\u00b0";
export const WEATHERBUG_HOURLY_URL = "https://www.weatherbug.com/weather-forecast/hourly/";
export const SEARCH_URL = "../search";
export const MAX_DESC_LENGTH = 200;
export const PRIVACY_LINK = "/legal";
export const REUTERS_PAGE_TITLE = "InstantNews By Reuters";
export const WEATHERBUG_PAGE_TITLE = "InstantNews By WeatherBug";
export const HOME_PAGE_TITLE = "InstantNews";
export const HOME_ALS_INSTALL_LINK =
  "https://instantnews_als.ampxdirect.com/?partner=instantnews_als&sub1=amp_instantnews&sub2=install";
export const HOME_ALS_REINSTALL_LINK =
  "https://instantnews_als.ampxdirect.com/?partner=instantnews_als&sub1=amp_instantnews&sub2=reinstall";
export const HOME_PIXEL_LINK =
  "//tracking.admarketplace.net/tracking?pid=33111&cid=74677&orderval=0.00&v=2&orderid=&custominfo=";
export const REUTERS_ALS_INSTALL_LINK =
  "https://instantnews_als.ampxdirect.com/?partner=instantnews_als&sub1=reuters&sub2=install";
export const REUTERS_ALS_REINSTALL_LINK =
  "https://instantnews_als.ampxdirect.com/?partner=instantnews_als&sub1=reuters&sub2=reinstall";
export const REUTERS_PIXEL_LINK =
  "//tracking.admarketplace.net/tracking?pid=33021&cid=74623&orderval=0.00&v=2&orderid=&custominfo=";
export const WEATHERBUG_ALS_INSTALL_LINK =
  "https://instantnews_als.ampxdirect.com/?partner=instantnews_als&sub1=weatherbug&sub2=install";
export const WEATHERBUG_ALS_REINSTALL_LINK =
  "https://instantnews_als.ampxdirect.com/?partner=instantnews_als&sub1=weatherbug&sub2=reinstall";
export const WEATHERBUG_PIXEL_LINK =
  "//tracking.admarketplace.net/tracking?pid=33067&cid=74639&orderval=0.00&v=2&orderid=&custominfo=";
export const CHROME_INSTANT_NEWS_LINK =
  "https://chrome.google.com/webstore/detail/instant-news-by-reuters/bkacaebcidokjlpmgnjhmnmdahfpakpl?hl=en-US";
export const DEFAULT_WEATHER_INFO = {
  temperatureLow: 0,
  temperatureHigh: 0,
  iconCode: 0,
  temperature: 0,
  feelsLike: 0,
  windSpeed: 0,
  humidity: 0,
  dewPoint: 0,
  pressureSeaLevel: 0,
  iconDescription: "Sunny",
  windDirection: 0,
};
